<template>
    <picture v-if="!pending && !!media">
        <div class="image-container">
            <SkeletonLoader v-if="contentPending" v-bind:class="imageClass" />
            <img 
                ref="imageElement"
                v-bind:src="mediaStore.getContentUri(mediaId, usePreview ?? false)" 
                v-bind:alt="media.description ?? ''" 
                v-bind:class="imageClass" 
                loading="lazy"
                v-on:load="contentPending = false"
            />
        </div>
        <NuxtLink v-if="!!useLink" v-bind:to="'/gallery/' + media.id">
            <span>Vedi più informazioni</span>
        </NuxtLink>
        <template v-if="media.description !== '' && !!media.description">
            <caption v-if="showCaption">{{ media.description }}</caption>
            <caption v-else class="collapsed">{{ media.description }}</caption>
        </template>
    </picture>
    <SkeletonLoader v-else />
</template>

<script setup lang="ts">
const { mediaId, useLink, showCaption, imageClass, usePreview } = defineProps<{ 
    mediaId: string, 
    showCaption: boolean, 
    useLink?: boolean, 
    imageClass?: string, 
    usePreview?: boolean,
 }>()

const mediaStore = useMediaStore()

const imageElement = ref<HTMLImageElement>()
const contentPending = ref(true)
const { data: media, pending } = await useLazyAsyncData("fetch-media-" + mediaId, () => mediaStore.fetchOrGetAsync(mediaId))
</script>

<style scoped>
picture {
    position: relative;
}

a > span {
    display: none;
}

a::after {
    position: absolute;
    content: '';
    inset: 0;
}

caption {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--grey-transparent-200);
    color: var(--grey-900);
    font-size: var(--font-size-small);
    padding: var(--m-small);
    text-wrap: wrap;
}

caption.collapsed {
    visibility: collapse;
}

.image-container {
    isolation: isolate;
    position: relative;
    width: 100%;
    height: 100%;
}

.image-container > :first-child {
    z-index: -1;
    position: absolute;
    inset: 0;
}
</style>